
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { RelatorioService } from '@/core/services/financeiro';
import { ReguaCobrancaService } from '@/core/services/regua';
import { Contrato } from '@/core/models/cadastros';
import { ContratoService } from '@/core/services/cadastros';
import { TratarErroApi } from '@/assets/scripts/helper';

@Component
export default class PainelCobranca extends mixins(Vue, ListPage) {
  filter: any = new Model();

  service = new RelatorioService()
  serviceRegua = new ReguaCobrancaService();
  etapaModel = new Etapas()

  lista: any = [];
  etapasx: any = [];
  etapas: any = [];

  paginations = []
  currentPageCobrar = 1;
  perPage = 3;
    isLoading: boolean = false;

  DialogGerenciadorContrato: boolean = false;
  contrato = new Contrato();
  contratoService = new ContratoService();

  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi' },
    { text: 'Painel de Inadimplência', disabled: true, href: '/financeiro/painel/cobranca' }
  ];

  titulo: string = 'Painel de cobrança';
  subTitulo: string = 'tipos de bancos cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15
  };

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
  ];

  contratosCanceladosGraphs: any[] = [
    {
      1: { text: 'JAN', value: 12 },
      2: { text: 'FEV', value: 12 },
      3: { text: 'MAR', value: 13 },
    }
  ]

  @Watch("options", { deep: true })
  AtualizarLista() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined, 'Etapas').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning");
        } else {
          this.$swal("Aviso", err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }

  GetContrato(item) {
    this.contratoService.ObterPorId(item.id, "Receitas.Parcelas.Transacoes.UsuarioBoletoGerado, UnidadesAdicionais.Unidade, ContratosDigitais.Tipo, ContratosDigitais.Situacao, ContratosDigitais.Signatarios.Cliente, ContratosDigitais.Signatarios.Situacao, Receitas.Parcelas.AntecipacaoAmortizacao.Itens,TipoAmortizacao, Receitas.Parcelas.AntecipacaoComprovante, Observacoes, Receitas.Parcelas.Situacao, Receitas.Parcelas.Baixas.FormaPagamento,TipoIndice").then(
      res => {
        this.contrato = res.data;
        this.DialogGerenciadorContrato = true;
      },
      err => {
        this.$swal("Aviso", err.response.data, "warning");
      })
  }

  ObterRegua() {
    if (this.filter.id > 0) {
        this.isLoading = true;
      this.service.ObterRelatorioPainelInadimplencia(this.filter.id).then(
        (res) => {

       if (!res || !res.data) {
      this.isLoading = false;
    }
          this.etapaModel = res.data;
          this.etapaModel.etapas = res.data.etapas
            this.isLoading = false;

        },
         (err) => {
        this.isLoading = false;
        TratarErroApi(err)
      }
      )
    }
  }

  GetReguaCobracaContrato() {
    this.loading = true;
    this.etapas = [];
    if (this.filter.id > 0) {
      this.serviceRegua.GetContratos(this.filter.id).then(
        res => {
          this.etapasx = res.data.etapas.map((x) => ({
            page: 1,
            pageCount: 0,
            minimoDiasVencido: x.minimoDiasVencido,
            maximoDiasVencido: x.maximoDiasVencido,
            nome: x.nome,
            contratos: x.contratos
          }))
          this.loading = false;
        },
        err => {
          this.$swal("Aviso", err.response.data, "warning");
          this.loading = false;
        })
    }
  }

  @Watch("options", { deep: true })
  Atualizar() {
    this.serviceRegua.Listar(-1, -1, [], [], this.search, [], undefined, 'Etapas').then(
      res => {
        this.lista = res.data.items;
        this.filter.id = this.lista.id
        this.ObterRegua();
      },
    )
  }

  mounted() {
    this.Atualizar()
  }
}

class Model {
  id = null;
}

class Etapas {
  etapas: any = [];
  totalParcelas: number = 0;
  valorTotal: number = 0;
}
